// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-city-of-pharr-1-js": () => import("./../../../src/pages/city-of-pharr-1.js" /* webpackChunkName: "component---src-pages-city-of-pharr-1-js" */),
  "component---src-pages-city-of-pharr-2-js": () => import("./../../../src/pages/city-of-pharr-2.js" /* webpackChunkName: "component---src-pages-city-of-pharr-2-js" */),
  "component---src-pages-city-of-pharr-3-js": () => import("./../../../src/pages/city-of-pharr-3.js" /* webpackChunkName: "component---src-pages-city-of-pharr-3-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-f-word-gift-shop-js": () => import("./../../../src/pages/f-word-gift-shop.js" /* webpackChunkName: "component---src-pages-f-word-gift-shop-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-rio-grande-guardian-js": () => import("./../../../src/pages/rio-grande-guardian.js" /* webpackChunkName: "component---src-pages-rio-grande-guardian-js" */),
  "component---src-pages-warm-audio-js": () => import("./../../../src/pages/warm-audio.js" /* webpackChunkName: "component---src-pages-warm-audio-js" */)
}

